import React from "react"
import styled from "styled-components"
import addToMailchimp from "../lib/mailchimp"

const Button = styled.button`
  border: none;
  background: white;
  padding-top: 0.7rem;
  padding-bottom: 0.5rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  font-family: "MissionGothicBold", sans-serif;
  font-style: normal;
  font-size: 0.8rem;
  text-align: center;
  width: 200px;
  cursor: pointer;
  transition: 0.2s all linear;
  outline: 0;
  display: inline-block;
  margin-left: auto;
  margin-right: auto;

  ${props => props.isOpen && `margin-bottom: 8px;`}

  :disabled {
    cursor: default;
    color: #afa5a4;
    :hover {
      opacity: 1;
    }
  }
  :hover {
    opacity: 0.6;
  }
`

const Input = styled.input`
  border: none;
  padding-top: 0.5rem;
  padding-bottom: 0.3rem;
  padding-left: 8px;
  padding-right: 8px;
  font-family: "MissionGothicBold", sans-serif;
  font-size: 0.8rem;
  outline: 0;
  width: ${props => (props.halfWidth ? `50%` : `100%`)};
  display: inline-block;
  margin-bottom: 4px;
`

const AcceptMarketingBox = styled.input`
  font-family: "MissionGothicBold", sans-serif;
  font-style: normal;
  font-size: 0.8rem;
  margin-right: 16px;
  display: inline-block;
  vertical-align: middle;
  border: 1px solid #000;
  padding: 4px;
`

const AcceptMarketing = styled.div`
  font-family: "MissionGothicBold", sans-serif;
  font-style: normal;
  font-size: 0.8rem;
  display: inline-block;
  line-height: 1.5;
  vertical-align: middle;
  width: 90%;
  padding-right: 0px;
  color: #fff;
`

const Form = styled.form`
  max-width: 24rem;
  padding: 0px 24px;
  margin-left: auto;
  margin-right: auto;
`

const Meta = styled.div`
  font-family: "MissionGothicBold", sans-serif;
  font-size: 16px;
  color: white;
`

class SignUp extends React.Component {
  state = {
    success: undefined,
    firstName: "",
    lastName: "",
    email: "",
    acceptsMarketing: false,
  }
  updateEmail = e => {
    this.setState({ email: e.target.value })
  }
  updateFirstname = e => {
    this.setState({ firstName: e.target.value })
  }
  updateLastname = e => {
    this.setState({ lastName: e.target.value })
  }
  updateAcceptsMarketing = e => {
    this.setState({ acceptsMarketing: e.target.checked })
  }

  _handleSubmit = async e => {
    e.preventDefault()

    const { email, firstName, lastName, acceptsMarketing } = this.state

    if ((acceptsMarketing, email, firstName, lastName)) {
      const { endpoint } = this.props
      const listFields = {
        FNAME: firstName,
        LNAME: lastName,
      }

      const result = await addToMailchimp(email, listFields, endpoint)

      this.setState({
        success: result,
        firstName: "",
        lastName: "",
        email: "",
      })
    }
  }

  render() {
    const { email, firstName, lastName, success, acceptsMarketing } = this.state
    return (
      <Form>
        <div className="mb3">
          <Input
            type="email"
            placeholder="Enter email..."
            value={email}
            onChange={this.updateEmail}
            marginBottom="8px"
          />
          <Input
            type="text"
            placeholder="Enter first name..."
            value={firstName}
            onChange={this.updateFirstname}
          />
          <Input
            type="text"
            placeholder="Enter last name..."
            value={lastName}
            onChange={this.updateLastname}
          />

          <div className="pt3 mb3">
            <AcceptMarketingBox
              type="checkbox"
              value={acceptsMarketing}
              onChange={this.updateAcceptsMarketing}
            />
            <AcceptMarketing>
              I accept the terms and conditions and privacy policy of Dear
              Leader Sunglasses.
            </AcceptMarketing>
          </div>

          <div className="w-100 tc">
            <Button
              onClick={this._handleSubmit}
              disabled={!acceptsMarketing || !email || !firstName || !lastName}
            >
              Sign up
            </Button>
            {success && (
              <Meta className="lh-copy pt2">
                {success.result === "success"
                  ? `You are now signed up! Thank you.`
                  : success.msg}
              </Meta>
            )}
          </div>
        </div>
      </Form>
    )
  }
}

export default SignUp
