import React from "react"
import Layout from "../components/layout"
import styled from "styled-components"
import SEO from "../components/seo"
import ProductImageScroller from "../components/ProductImageScroller"
import Swipe from "../components/swipe"

const Section = styled.div`
  width: 100%;
  ${props =>
    !props.noMinHeight &&
    ` height: 100vh;
  min-height: 600px;`}

  padding: ${props => props.padding};

  ${props => props.last && `margin-bottom: -8px;`}

  ${props =>
    !props.noFlex &&
    `display: flex;
  align-items: center;
  text-align: center;`}

  ${props =>
    props.backgroundImage &&
    `background-repeat: no-repeat;
  background-position: center center;
  background-size: cover !important;
  background-image: url(${props.backgroundImage});`}

  ${props =>
    props.backgroundColor && `background-color: ${props.backgroundColor};`}
    
`

const Name = styled.div`
  padding: 8px;
  line-height: 1;
  font-size: 32px;
  font-family: "MissionGothicBold", sans-serif;
  color: ${props => (props.black ? `#000` : `#fff`)};
  text-align: center;
  ${props => props.moreMargin && `margin-bottom: 16px;`}

  @media screen and (min-width: 30em) {
    font-size: 48px;
  }
`

const Shape = styled.div`
  font-size: 20px;
  font-family: "MissionGothicBold", sans-serif;
  color: ${props => (props.black ? `#000` : `#fff`)};
  text-align: center;
  text-transform: uppercase;

  @media screen and (min-width: 30em) {
    font-size: 24px;
  }
`

const GlassesIntro = styled.p`
  max-width: 480px;
  font-size: 16px;
  font-family: "MissionGothicBold", sans-serif;
  color: #000;
  line-height: 1.5;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  padding-left: 16px;
  padding-right: 16px;

  @media screen and (min-width: 30em) {
    padding: 0px;
  }
`

const Charity = styled.div`
  font-size: 24px;
  font-family: "MissionGothicBold", sans-serif;
  color: #fff;
  line-height: 1.5;
`

const Quote = styled.div`
  padding: 24px;
  line-height: 1.5;
  font-size: 24px;
  font-family: "MissionGothicBold", sans-serif;
  color: #000;
  text-align: center;

  @media screen and (min-width: 30em) {
    padding: 24px 80px;
  }

  @media screen and (min-width: 60em) {
    padding: 24px 160px;
  }
`

const Introduction = styled.div`
  padding: 40px 24px 20px 24px;
  margin-bottom: 8px;
  width: 100%;
  display: block;
  margin-left: auto;
  margin-right: auto;
  text-align: left;

  @media screen and (min-width: 30em) {
    padding: 40px 24px 0px 40px;
  }

  @media screen and (min-width: 60em) {
    width: 40%;
    display: inline-block;
    vertical-align: top;
    padding-left: 80px;
    padding-top: 80px;
    margin-left: 0;
    margin-right: 0;
  }
`

const ImageColumn = styled.div`
  width: 100%;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: -5px;

  @media screen and (min-width: 30em) {
    padding: 40px;
  }

  @media screen and (min-width: 60em) {
    width: 60%;
    display: inline-block;
    padding-left: 80px;
    padding-top: 40px;
    margin-left: 0;
    margin-right: 0;
  }
`

const Title = styled.h3`
  font-size: 20px;
  font-family: "MissionGothicBold", sans-serif;
  color: #000;
  margin-bottom: 8px;
  line-height: 1.5;
`

const Paragraph = styled.p`
  font-size: 16px;
  font-family: "MissionGothicBold", sans-serif;
  color: #000;
  line-height: 1.5;
`

const CardContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

class LeaderPage extends React.Component {
  render() {
    const leader = this.props.data.contentfulLeader
    return (
      <Layout>
        <SEO
          title={`${leader.name} ${leader.lastName} for ${leader.charity}`}
          keywords={[
            `freedom`,
            `${leader.name} ${leader.lastName}`,
            `${leader.charity}`,
          ]}
          description={leader.overview.overview}
        />
        <Section
          backgroundImage={leader.image.fixed.src}
          padding="40px 0px 0px 0px"
        >
          <div className="w-100">
            <Name>
              {leader.name} {leader.lastName}
            </Name>
            <Charity>supporting {leader.charity}</Charity>
          </div>
        </Section>

        <Section noMinHeight padding="10px 0px 0px 0px">
          <div className="w-100">
            <Name black>{leader.name}</Name>
            <Shape black>{leader.shape} shape</Shape>
            <GlassesIntro>{leader.glassesIntro}</GlassesIntro>
            <CardContainer>
              <ProductImageScroller images={leader.gallery} />
              <Swipe />
            </CardContainer>
            <Quote>"{leader.quote}"</Quote>
          </div>
        </Section>

        <Section
          noFlex
          noMinHeight
          backgroundColor={leader.color || "#bcd4f0"}
          padding="40px 0px"
        >
          <Introduction>
            <Title>{leader.overview.overview}</Title>
            <Paragraph>{leader.description.description}</Paragraph>
          </Introduction>
          <ImageColumn>
            <img src={leader.charityBG.fixed.src} alt={leader.charity} />
          </ImageColumn>
        </Section>

        <Section noMinHeight backgroundColor={leader.color || "#bcd4f0"} last>
          <div className="w-100">
            <Name black moreMargin>
              Watch {leader.name}'s talk
            </Name>
            <iframe
              width="100%"
              height="600"
              title={leader.name + `talk`}
              src={leader.youTube}
              frameBorder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            />
          </div>
        </Section>
      </Layout>
    )
  }
}

export default LeaderPage

export const pageQuery = graphql`
  query LeaderBySlug($slug: String!) {
    contentfulLeader(slug: { eq: $slug }) {
      name
      lastName
      color
      charity
      slug
      quote
      youTube
      shape
      glassesIntro
      description {
        id
        description
      }
      overview {
        id
        overview
      }
      image {
        fixed(width: 1600, quality: 100) {
          width
          height
          src
          srcSet
        }
      }
      charityBG {
        fixed(width: 1000, quality: 100) {
          width
          height
          src
          srcSet
        }
      }
      sunglass {
        fixed(width: 1000, quality: 100) {
          width
          height
          src
          srcSet
        }
      }
      gallery {
        fixed(width: 1600, quality: 100) {
          width
          height
          src
          srcSet
        }
      }
    }
  }
`
