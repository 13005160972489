import { Link } from "gatsby"
import styled from "styled-components"
import PropTypes from "prop-types"
import React from "react"
import LogoWhite from "../images/Logo-white.svg"

const TopNav = styled.div`
  position: fixed;
  padding-left: 16px;
  padding-top: 16px;
  z-index: 2147483647;
`

const Logo = styled.img`
  width: 160px;
  padding-top: 8px;
`

const Header = () => (
  <TopNav>
    <Link to="/">
      <Logo src={LogoWhite} className="dim" />
    </Link>
  </TopNav>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
