import React from "react"
import styled from "styled-components"
import SignUp from "./signUp"

import DearLeader from "../images/DearLeaderWhite.png"

const Container = styled.div`
  min-height: 300px;
  width: 100%;
  background-color: ${props => props.backgroundColor};

  ${props => props.mobilePadding && `padding: ${props.mobilePadding};`}

  ${props => props.background && `background-image: url(${props.background});`}

    ${props =>
      props.centerItems &&
      `display: flex;
      align-items: center;`}

  ${props => props.hideOnMobile && "display: none;"}

  @media screen and (min-width: 30em) {
    ${props => props.padding && `padding: ${props.padding};`}
  }
`

const Introduction = styled.h1`
  text-align: center;
  font-size: 24px;
  font-family: "MissionGothicBold", sans-serif;
  line-height: 1.5;
  margin-bottom 24px;
  padding: 0px 24px;
  color: ${props => props.color};

  @media screen and (min-width: 30em) {
    font-size: 32px;  
  }

`

const Paragraph = styled.p`
  text-align: center;
  font-size: 20px;
  font-family: "MissionGothicBold", sans-serif;
  line-height: 1.5;
  margin-bottom 32px;
  color: ${props => props.color};
  padding: 0px 24px;
  max-width: 48rem;
  margin-left: auto;
  margin-right: auto;

  @media screen and (min-width: 30em) {
    padding: 0px 80px;
    font-size: 24px;
  }

  @media screen and (min-width: 60em) {
    padding: 0px 80px;
  }
`

const Meta = styled.div`
  font-family: "MissionGothicBold", sans-serif;
  text-align: center;
  font-size: 14px;
  padding-bottom: 32px;
  background-color: #bcd4f0;
  color: #fff;
`

export default function Footer() {
  return (
    <div>
      <Container
        backgroundColor="#bcd4f0"
        centerItems
        padding="56px 0px"
        mobilePadding="32px 0px"
      >
        <div className="w-100">
          <Introduction color="#fff">
            Sign up and be the first to know when we launch!
          </Introduction>
          <Paragraph color="#fff">
            Sign up and be first in line when we launch the Freedom Collection.
            You will also be supporting the project for freedom.
          </Paragraph>
          <SignUp endpoint="https://alexanderbach.us20.list-manage.com/subscribe/post?u=ff6b591fdfe041f0472d04ba9&amp;id=dc82f5e109" />
        </div>
      </Container>
      <Meta>
        <div className="mr1 dib">A charity project by</div>{" "}
        <a
          href="https://dearleader.com"
          target="_blank"
          rel="noopener noreferrer"
          className="dim"
        >
          <img
            src={DearLeader}
            width="100"
            className="img dib"
            alt="Dear Leader"
          />
        </a>
      </Meta>
    </div>
  )
}
