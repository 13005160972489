import React from "react"
import styled from "styled-components"

const Card = styled.section`
  width: 100%;
  height: 600px;
  display: flex;
  overflow-x: auto;

  ::-webkit-scrollbar {
    display: none;
  }
  -webkit-overflow-scrolling: touch;
`

const CardContent = styled.div`
  width: 800px;
  min-width: 800px;
  margin: 0;

  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover !important;

  ${props =>
    props.background && `background-image: url(${props.background.file});`}

  @media screen and (min-width: 30em) {
    width: 1000px;
    min-width: 1000px;
  }
`

const SingleItem = file => <CardContent background={file} />

export default function ProductImageScroller({ images }) {
  return (
    <Card className="z-max">
      {images.map((image, i) => (
        <SingleItem key={i} file={image.fixed.src} />
      ))}
    </Card>
  )
}
