import React from "react"
import styled from "styled-components"
import Arrow from "../assets/images/arrow-forward-outline.svg"

const SwipeContainer = styled.div`
  position: absolute;
  font-family: "MissionGothicBold", sans-serif;
  font-size: 16px;
  color: white;
  right: 0;
  padding-right: 24px;
`

const SwipeIcon = styled.img`
  color: white;
  fill: white;
`

export default function Swipe({}) {
  return (
    <SwipeContainer className="z-max">
      <SwipeIcon src={Arrow} s width="40" />
    </SwipeContainer>
  )
}
